import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Switch, Space, Button, Upload, message } from 'antd'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { UploadOutlined } from '@ant-design/icons'

import { xiaoliToolsMenuDetail, createXiaoliToolsMenus, saveXiaoliToolsMenuDetail } from 'api/xiaoli'

import styles from './index.module.scss'

const { TextArea } = Input
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 }
}

const XiaoliToolsDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm()
    const [formData, setFormData] = useState({
        title: undefined,
        icon: [],
        link: undefined,
        desc: undefined,
        isActive: false,
        isLogin: false
    })

    const getXiaoliToolsMenuDetail = async () => {
        try {
            const res = await xiaoliToolsMenuDetail(id)

            if (res.statusCode === 200) {
                const { data = {} } = res
                const icons = data?.icon.split('/')
                const formData = {
                    ...res.data,
                    icon: [
                        {
                            uid: data.icon,
                            name: icons[icons.length - 1],
                            status: 'done',
                            url: data.icon
                        }
                    ]
                }
                form.setFieldsValue(formData)

                console.log('formData', formData)
                setFormData(formData)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const create = async () => {
        try {
            await form.validateFields()

            const data = form.getFieldsValue()
            console.log('data', data)
            const res = await createXiaoliToolsMenus({
                ...data,
                icon: data.icon?.[0]?.response?.data?.url
            })

            if (res.statusCode === 200) {
                message.success('新建菜单成功')
                navigate(-1)
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    const save = async () => {
        try {
            await form.validateFields()

            const data = form.getFieldsValue()

            const res = await saveXiaoliToolsMenuDetail({
                id,
                ...data,
                icon: data.icon?.[0]?.response?.data?.url || data.icon?.[0]?.url
            })

            if (res.statusCode === 200) {
                message.success('更新菜单成功')
                navigate(-1)
            } else {
                message.error(res?.message || '更新失败')
            }
        } catch (err) {
            console.log('err', err)
        }
    }

    useEffect(() => {
        if (id === 0) {
            form.setFieldsValue({
                title: undefined,
                icon: [],
                link: undefined,
                desc: undefined,
                isActive: false,
                isLogin: false
            })
        } else {
            getXiaoliToolsMenuDetail()
        }
    }, [location])

    return (
        <div>
            <Card>
                <Form form={form} {...layout} initialValues={formData} className={styles.form} style={{ maxWidth: 600 }} onFinish={id === '0' ? create : save}>
                    <p className={styles.id}>{id === '0' ? '新建工具箱菜单' : `工具箱菜单ID: ${id}`}</p>
                    <Form.Item label="标题" name="title" rules={[{ required: true, message: '请输入标题!' }]}>
                        <Input placeholder="请输入标题" />
                    </Form.Item>
                    <Form.Item label="副标题" name="desc" rules={[{ required: true, message: '请输入副标题!' }]}>
                        <TextArea placeholder="请输入副标题" />
                    </Form.Item>
                    <Form.Item label="图标" name="icon" valuePropName="fileList" getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)} rules={[{ required: true, message: '请输入图标!' }]}>
                        <Upload
                            name="file"
                            data={{
                                path: `xiaoli/icon`
                            }}
                            className="avatar-uploader"
                            action="https://muyuxiao.cn/api/updateFie"
                            maxCount={1}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item label="链接" name="link" rules={[{ required: true, message: '请输入链接!' }]}>
                        <Input placeholder="请输入链接" />
                    </Form.Item>
                    <Form.Item label="是否展示" name="isActive" style={{ textAlign: 'left' }} valuePropName="checked">
                        <Switch checkedChildren="展示" unCheckedChildren="隐藏" />
                    </Form.Item>
                    <Form.Item label="授权登录" name="isLogin" style={{ textAlign: 'left' }} valuePropName="checked">
                        <Switch checkedChildren="需要" unCheckedChildren="不需要" />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                保存
                            </Button>
                            <Button htmlType="button" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default XiaoliToolsDetail
