import React, { useState, useEffect } from 'react'
import { Descriptions, Image, Card } from 'antd'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { xiaoliUser } from 'api/xiaoli'

const XiaoliUserDetail = () => {
    const { id } = useParams()
    const [userInfo, setUserInfo] = useState()

    console.log('userInfo', userInfo)
    const items = [
        {
            label: 'ID',
            children: userInfo?.id
        },
        {
            label: '头像',
            children: <Image src={userInfo?.avatar} width={80} height={80} style={{ borderRadius: 80 }} />
        },
        {
            label: '昵称',
            children: userInfo?.nickname
        },
        {
            label: '性别',
            children: userInfo?.gender === 1 ? '男' : userInfo?.gender === 2 ? '女' : '未知'
        },
        {
            label: '手机号',
            children: userInfo?.phone
        },
        {
            label: 'openId',
            children: userInfo?.openid
        },
        {
            label: '国家',
            children: userInfo?.country || '-'
        },
        {
            label: '省份',
            children: userInfo?.province || '-'
        },
        {
            label: '城市',
            children: userInfo?.city || '-'
        },
        {
            label: '状态',
            children: userInfo?.status === 1 ? '正常使用中' : ''
        },
        {
            label: '注册时间',
            children: dayjs(userInfo?.createDate).format('YYYY年MM月DD日 HH时mm分ss秒')
        }
    ]

    const getUser = async () => {
        try {
            const res = await xiaoliUser(id)

            if (res.statusCode === 200) setUserInfo(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        console.log(13213)
        getUser()
    }, [])

    return (
        <div>
            <Card>
                <Descriptions items={items} column={1} />
            </Card>
        </div>
    )
}

export default XiaoliUserDetail
