import React, { useState, useEffect } from 'react'
import { Button, Form, Switch, Table, Input, Popconfirm, message } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'

import { xiaoliToolsMenus, xiaoliToolsMenusetIsActive, xiaoliToolsMenusetIsActiveAll, deleteXiaoliToolsMenuset } from 'api/xiaoli'

import styles from './index.module.scss'

const XiaoliToolsList = () => {
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()
    const location = useLocation()
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: '标题',
            dataIndex: 'title'
        },
        {
            title: '是否展示',
            dataIndex: 'isActive',
            render: (row, _) => (
                <Switch
                    checkedChildren="展示"
                    unCheckedChildren="隐藏"
                    value={row}
                    onChange={(e) =>
                        setIsActive({
                            id: _.id,
                            isActive: e
                        })
                    }
                />
            )
        },
        {
            title: '最后更新时间',
            dataIndex: 'updateDate',
            render: (row) => <span>{dayjs(row).format('YYYY-MM-DD HH:mm:ss')}</span>
        },
        {
            title: '操作',
            width: 140,
            dataIndex: 'id',
            render: (row) => (
                <>
                    <span style={{ marginRight: 10, color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate('/xiaoli/tools/' + row)}>
                        详情
                    </span>
                    <Popconfirm placement="topRight" title="是否确定删除?" description="是不是要删除这个菜单呀?~" onConfirm={() => handleDelete(row)} okText="确定" cancelText="取消">
                        <span style={{ marginRight: 10, color: '#ff4d4f', cursor: 'pointer' }}>删除</span>
                    </Popconfirm>
                </>
            )
        }
    ]

    const setIsActive = async (parameter) => {
        try {
            const res = await xiaoliToolsMenusetIsActive(parameter)

            res.statusCode === 200
                ? getToolsList(currentPage)
                : messageApi.open({
                      type: 'error',
                      content: res?.message || '设置失败'
                  })
        } catch (err) {
            console.log(err)
        }
    }

    const setIsActiveAll = async (isActive) => {
        try {
            const res = await xiaoliToolsMenusetIsActiveAll({ isActive })

            res.statusCode === 200
                ? getToolsList(currentPage)
                : messageApi.open({
                      type: 'error',
                      content: res?.message || '设置失败'
                  })
        } catch (err) {
            console.log(err)
        }
    }

    const getToolsList = async (cPage, pSize) => {
        try {
            setLoading(() => true)
            const { title } = searchForm.getFieldsValue()

            const res = await xiaoliToolsMenus({
                title,
                currentPage: cPage || currentPage || 1,
                pageSize: pSize || pageSize || 10
            })

            if (res.statusCode === 200) {
                const { data, page } = res
                setDataSource(data)
                setCurrentPage(page.currentPage)
                setPageSize(page.pageSize)
                setTotal(page.total)
            }
        } catch (err) {
            console.log(err)
        }

        setLoading(() => false)
    }

    const handleDelete = async (id) => {
        try {
            const res = await deleteXiaoliToolsMenuset({
                id
            })

            if (res.statusCode === 200) {
                message.success('删除成功')
                getToolsList()
            } else {
                message.error(res?.message || '删除失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const reset = () => {
        searchForm.resetFields()
        getToolsList(1)
    }

    useEffect(() => {
        getToolsList()
    }, [location])

    return (
        <div>
            {contextHolder}
            <Form form={searchForm} className={styles.form} layout="inline" onFinish={() => getToolsList(1)}>
                <Form.Item label="标题" name="title">
                    <Input className={styles.inputNumber} placeholder="请输入标题" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className={styles.mr10} htmlType="submit">
                        查找
                    </Button>
                    <Button className={styles.mr10} onClick={() => reset()}>
                        重置
                    </Button>
                </Form.Item>
            </Form>

            <Form className={styles.form} layout="inline">
                <Button type="primary" className={styles.mr10} onClick={() => navigate('/xiaoli/tools/0')}>
                    新建
                </Button>
                <Popconfirm
                    title="请选择你需要的批量操作"
                    description="你确定要批量操作全部菜单?"
                    onConfirm={() => setIsActiveAll(true)}
                    onCancel={() => setIsActiveAll(false)}
                    okText="展示"
                    cancelText="隐藏">
                    <Button type="primary">批量是否展示</Button>
                </Popconfirm>
            </Form>

            <Table
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                rowKey={(e) => e.id}
                pagination={{
                    currentPage,
                    pageSize,
                    total,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50]
                }}
                onChange={(e) => getToolsList(e.current, e.pageSize)}
            />
        </div>
    )
}

export default XiaoliToolsList
