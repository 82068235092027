import React, { useState, useEffect } from 'react'
import { Button, Form, InputNumber, Table, Image, Input } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import { xiaoliUserList } from 'api/xiaoli'

import styles from './index.module.scss'

const XiaoliUserList = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: '用户ID',
            dataIndex: 'id'
        },
        {
            title: '用户头像',
            dataIndex: 'avatar',
            render: (row) => <Image src={row} width={40} height={40} />
        },
        {
            title: '昵称',
            dataIndex: 'nickname'
        },
        {
            title: '手机号',
            dataIndex: 'phone'
        },
        {
            title: '注册时间',
            dataIndex: 'createDate',
            render: (row) => <span>{dayjs(row).format('YYYY-MM-DD HH:mm:ss')}</span>
        },
        {
            title: '操作',
            width: 90,
            dataIndex: 'id',
            render: (row) => {
                return (
                    <>
                        <span style={{ marginRight: 10, color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate('/xiaoli/user/' + row)}>
                            详情
                        </span>
                    </>
                )
            }
        }
    ]

    const getUserList = async (cPage, pSize) => {
        try {
            setLoading(true)
            const { nickname, phone } = form.getFieldsValue()

            const res = await xiaoliUserList({
                nickname,
                phone,
                currentPage: cPage || currentPage || 1,
                pageSize: pSize || pageSize || 10
            })

            if (res.statusCode === 200) {
                const { data, page } = res
                setDataSource(data)
                setCurrentPage(page.currentPage)
                setPageSize(page.pageSize)
                setTotal(page.total)
            }
        } catch (err) {
            console.log(err)
        }

        setLoading(false)
    }

    const reset = () => {
        form.resetFields()
        getUserList(1)
    }

    useEffect(() => {
        getUserList()
    }, [])

    return (
        <div>
            <Form form={form} className={styles.form} layout="inline" onFinish={() => getUserList(1)}>
                <Form.Item label="昵称" name="nickname">
                    <Input className={styles.inputNumber} placeholder="可输入昵称" />
                </Form.Item>
                <Form.Item label="手机号" name="phone">
                    <InputNumber className={styles.inputNumber} placeholder="可输入手机号" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className={styles.query} htmlType="submit">
                        查找
                    </Button>
                    <Button onClick={() => reset()}>重置</Button>
                </Form.Item>
            </Form>

            <Table
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                rowKey={(e) => e.id}
                pagination={{
                    current: currentPage,
                    pageSize,
                    total,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50]
                }}
                onChange={(e) => getUserList(e.current, e.pageSize)}
            />
        </div>
    )
}

export default XiaoliUserList
