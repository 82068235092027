import request from 'api/request'

// 获取小栗助手用户列表
export const xiaoliUserList = (data = {}) => {
    return request('/api/admin/xiaoli/users', { method: 'POST', data })
}

// 获取小栗助手用户详情
export const xiaoliUser = (id) => {
    return request('/api/admin/xiaoli/user/' + id, { method: 'GET' })
}

// 获取小栗助手工具箱菜单列表
export const xiaoliToolsMenus = (data = {}) => {
    return request('/api/admin/xiaoli/toolsMenus/menus', { method: 'POST', data })
}

// 获取小栗助手工具箱菜单详情
export const xiaoliToolsMenuDetail = (id) => {
    return request('/api/admin/xiaoli/toolsMenus/' + id, { method: 'GET' })
}

// 新建小栗助手工具箱菜单列表
export const createXiaoliToolsMenus = (data = {}) => {
    return request('/api/admin/xiaoli/toolsMenus/create', { method: 'POST', data })
}

// 保存小栗助手工具箱菜单详情
export const saveXiaoliToolsMenuDetail = (data) => {
    return request('/api/admin/xiaoli/toolsMenus/save', { method: 'POST', data })
}

// 单个修改小栗助手工具箱菜单是否展示
export const xiaoliToolsMenusetIsActive = (data) => {
    return request('/api/admin/xiaoli/toolsMenus/setIsActive', { method: 'POST', data })
}

// 批量修改小栗助手工具箱菜单是否展示
export const xiaoliToolsMenusetIsActiveAll = (data) => {
    return request('/api/admin/xiaoli/toolsMenus/setIsActiveAll', { method: 'POST', data })
}

// 删除小栗助手工具箱菜单
export const deleteXiaoliToolsMenuset = (data) => {
    return request('/api/admin/xiaoli/toolsMenus/delete', { method: 'POST', data })
}
