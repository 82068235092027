import { createBrowserRouter } from 'react-router-dom'

import Login from 'pages/login'
import Signin from 'pages/signin'
import Forget from 'pages/forget'
import Welcome from 'pages/welcome'
import Layout from 'pages/layout'
import XiaoliUserList from 'pages/xiaoli/user/list'
import XiaoliUserDetail from 'pages/xiaoli/user/detail'
import XiaoliToolsList from 'pages/xiaoli/tools/list'
import XiaoliToolsDetail from 'pages/xiaoli/tools/detail'
import AccountAudit from 'pages/account/audit'
import AccountInfo from 'pages/account/info'

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/signin',
        element: <Signin />
    },
    {
        path: '/forget',
        element: <Forget />
    },
    {
        Component: Layout,
        children: [
            {
                index: true,
                path: '/',
                element: <Welcome />
            },
            {
                path: '/xiaoli/user',
                element: <XiaoliUserList />
            },
            {
                path: '/xiaoli/user/:id',
                element: <XiaoliUserDetail />
            },
            {
                path: '/xiaoli/tools',
                element: <XiaoliToolsList />
            },
            {
                path: '/xiaoli/tools/:id',
                element: <XiaoliToolsDetail />
            },
            {
                path: '/account/audit',
                element: <AccountAudit />
            },
            {
                path: '/account/info',
                element: <AccountInfo />
            }
        ]
    }
])

export default router
